<template>
    <div class="container-fluid">
        <div class="row mt-4">
            <div class="col-12">
                <div class="card">
                    <div class="card-body">
                        <div class="row align-items-center">
                            <div class="col ml-n2">
                                <h4 class="mb-1">
                                    <a>{{$t('endorsements.show.confession')}} <strong># {{item.id}}</strong></a>
                                </h4>
                            </div>
                            <div class="col-auto">
                                <router-link :to="path+'/'+item.id + '/edit'" class="btn btn-sm btn-primary d-none d-md-inline-block">
                                    <i class="fas fa-edit"></i>
                                </router-link>
                                <a @click="print(item.id)" class="btn btn-sm btn-primary d-none d-md-inline-block">
                                    <i class="fas fa-print"></i>
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-12">
                <div class="card">
                    <div class="card-header">
                        <h4 class="card-header-title">
                            <i class="fal fa-file-signature"></i>
                            {{$t('endorsements.show.expenseDetails')}}
                        </h4>
                    </div>
                    <div class="card-body">
                        <div class="row">
                            <div class="col-md-4">
                                <table class="table table-sm table-nowrap customed-table-small">
                                    <tbody>
                                        <tr>
                                            <th scope="row">{{$t('endorsements.show.recipientsName')}} </th>
                                            <td>{{item.name != null ? item.name : '--'}}</td>
                                        </tr>
                                        <tr>
                                            <th scope="row">{{$t('endorsements.show.CardNumber')}}</th>
                                            <td>{{item.identification_number != null ? item.identification_number : '--'}}</td>
                                        </tr>
                                        <tr>
                                            <th scope="row">{{$t('endorsements.show.date')}}</th>
                                            <td>{{$dateFormat(item.date)}}</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                        <div col-12>
                            <div class="w-100" v-if="item.description != null">
                                <hr class="my-3">
                                <h4 class="text-uppercase">{{$t('endorsements.show.description')}}</h4>
                                <p class="text-muted mb-0">{{item.description}}</p>
                            </div>
                            <div class="w-100" v-if="item.data1 != null">
                                <hr class="my-3">
                                <h4 class="text-uppercase">{{$t('endorsements.show.OtherData1')}}</h4>
                                <p class="text-muted mb-0">{{item.data1}}</p>
                            </div>
                            <div class="w-100" v-if="item.data2 != null">
                                <hr class="my-3">
                                <h4 class="text-uppercase">{{$t('endorsements.show.OtherData2')}}</h4>
                                <p class="text-muted mb-0">{{item.data2}}</p>
                            </div>
                            <div class="w-100" v-if="item.notes != null">
                                <hr class="my-3">
                                <h4 class="text-uppercase">{{$t('endorsements.show.notes')}}</h4>
                                <p class="text-muted mb-0">{{item.notes}}</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <print v-if="printInvoice"></print>
    </div>
</template>
<script>
    import axios from 'axios';
    
    import print   from './components/print.vue';
    export default {
        data() {
            return {
                path: '/endorsements',
                item: {},
                
                printInvoice: false,
            }
        },
        mounted() {
            this.getitem();
        },
        methods: {
            getitem() {
                axios.get(this.$linkGnirator(this.path + '/' + this.$route.params.id))
                    .then(response => {
                        this.item = response.data;
                    });
            },
            print (id) {
                this.$option.item_numberInvoice = id;
            }
        },
        components: {
            print
        }
    };

</script>
<style>
</style>
